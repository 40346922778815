import React from "react";
import { Container, Grid, Box } from "@mui/material";
import microscope from "./microscope.png";
import devices from "./devices.png";
import cloud from "./cloud_connect.png";
import arrow from "./arrow.png";
import arrow_v from "./arrow_vert.png";
import "./connect.css";

const flex = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "10px",
};

const isBrowser = typeof window !== "undefined";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

if (isBrowser) {
  window.addEventListener("scroll", reveal);
}

const Solution = () => {
  return (
    <Box marginBottom={{ xs: 10, sm: 10 }}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={10}
            sm={7}
            md={5}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
          >
            <h1>Streamline your workflow</h1>
            <p>
              With MicroREC Connect, we aim to empower you with cutting-edge
              tools that enhance your practice and streamline your workflow, so
              you can focus on what matters most -{" "}
              <b>delivering exceptional care to your patients.</b>
            </p>{" "}
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            display={{ xs: "none", sm: "block", md: "block" }}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={flex} className="reveal animate-glow delay-1">
                <img src={microscope} style={{ maxWidth: "100%" }}></img>
                <div>1. Take a picture or video</div>
              </div>
              <div className="reveal animate-glow delay-2">
                <img style={{ maxWidth: "100%" }} src={arrow}></img>
              </div>
              <div style={flex} className="reveal animate-glow delay-3">
                <img src={cloud} style={{ maxWidth: "100%" }}></img>
                <div>
                  2. Items are <b>automatically uploaded</b> to your MicroREC
                  Connect.
                </div>
              </div>
              <div className="reveal animate-glow delay-4">
                <img style={{ maxWidth: "100%" }} src={arrow}></img>
              </div>
              <div style={flex} className="reveal animate-glow delay-5">
                <img src={devices} style={{ maxWidth: "100%" }}></img>
                <div>
                  3. Instantly access it on <b>all of your devices</b>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            display={{ xs: "block", sm: "none", md: "none" }}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={flex} className="reveal animate-glow delay-1">
                <img src={microscope} style={{ maxWidth: "80%" }}></img>
                <div>1. Take a picture or video</div>
              </div>
              <div className="reveal animate-glow delay-2">
                <img
                  style={{ maxWidth: "80%", margin: "10px" }}
                  src={arrow_v}
                ></img>
              </div>
              <div style={flex} className="reveal animate-glow delay-3">
                <img src={cloud} style={{ maxWidth: "80%" }}></img>
                <div>
                  2. Items are <b>automatically uploaded</b> to your MicroREC
                  Connect.
                </div>
              </div>
              <div className="reveal animate-glow delay-4">
                <img style={{ maxWidth: "80%" }} src={arrow_v}></img>
              </div>
              <div style={flex} className="reveal animate-glow delay-5">
                <img
                  src={devices}
                  style={{ maxWidth: "80%", margin: "10px" }}
                ></img>
                <div>
                  3. Instantly access it on <b>all of your devices</b>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>{" "}
        <div style={{ textAlign: "center", marginTop: "5em" }}>
          <a
            href="https://calendar.app.google/sXa9CX7sA3jcPuSP8"
            target="_blank"
            style={{
              color: "white",
              backgroundColor: "rgb(142, 79, 255)",
              padding: "10px 20px",
              borderRadius: "20px",
              fontWeight: "600",
            }}
          >
            BOOK A DEMO
          </a>
        </div>
      </Container>
    </Box>
  );
};

export default Solution;
