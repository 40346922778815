import React from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import "../../static/carousel.css"; // requires a loader
import { Container, Grid } from "@mui/material";

const YoutubeSlide = ({ url, isSelected }) => (
  <ReactPlayer width="100%" url={url} playing={isSelected} muted />
);

const YoutubeGallery = () => {
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  const getVideoThumb = (videoId) =>
    `https://img.youtube.com/vi/${videoId}/default.jpg`;

  const getVideoId = (url) =>
    url.substr("https://www.youtube.com/embed/".length, url.length);

  const customRenderThumb = (children) =>
    children.map((item) => {
      const videoId = getVideoId(item.props.url);
      return <img src={getVideoThumb(videoId)} />;
    });

  return (
    <div style={{ backgroundColor: "#202026" }}>
      <Container style={{ padding: "0" }}>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2
                style={{
                  textAlign: "center",
                  width: "80%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "white",
                }}
              >
                MicroREC Connect Features
              </h2>
              <div
                style={{
                  textAlign: "center",
                  width: "80%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "2em",
                  fontSize: "20px",
                  color: "white",
                }}
              >
                Check out our youtube playlist where we explain in detail
                MicroREC Connect{" "}
              </div>
            </div>
            <Carousel
              axis={"vertical"}
              renderItem={customRenderItem}
              renderThumbs={customRenderThumb}
            >
              <YoutubeSlide
                key="youtube-2"
                url="https://www.youtube.com/embed/SvX9Az1u6W0"
              />
              <YoutubeSlide
                key="youtube-1"
                url="https://www.youtube.com/embed/W-UQW545p5w"
              />
              <YoutubeSlide
                key="youtube-3"
                url="https://www.youtube.com/embed/LEbGQY_v_0E"
              />
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default YoutubeGallery;
