import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import logo from "/src/images/iso_color.png";
import Subscribe from "../components/misc/check_list";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Grid } from "@mui/material";
import How from "../components/connect/how";
import Way from "../components/connect/the_way";
import Gallery from "../components/connect/gallery";
import Sticky from "../components/connect/sticky";
import Accordion from "../components/connect/accordion/accordion";
import Tabs from "../components/connect/tabs";
import Article from "../components/connect/article";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo
          title="MicroREC Connect | Custom Surgical"
          description="Data Management made easy. Your data is kept safe and secure, access it from any device, any time from anywhere."
        />{" "}
        <Subscribe />
        <>
          <Grid container xs={12} className="connect_main">
            <div className="text_over_connect_page">
              <b>MicroREC Connect</b>
              <Grid item xs={10} sm={6}>
                <div style={{ fontSize: "25px", marginTop: "1em" }}>
                  Keep your <b>data safe and secure</b>, access it from{" "}
                  <b>any device, any time from anywhere</b>.
                </div>
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "1em",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <button className="shop" style={{ marginRight: "1.5em" }}>
                  <a
                    href="https://connect.customsurgical.co/login"
                    target="_blank"
                    style={{ color: "white", fontSize: "20px" }}
                  >
                    LOGIN
                  </a>
                </button>
                <button
                  className="shop"
                  style={{
                    backgroundColor: "white",
                    marginTop: "10px",
                  }}
                >
                  <a
                    href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                    target="_blank"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                      color: "#FFA100",
                      fontSize: "18px",
                    }}
                  >
                    GET FREE TRIAL
                  </a>
                </button>
              </div>
            </div>
          </Grid>
        </>
        <Accordion></Accordion>
        <How></How>
        <Way></Way>
        <Sticky />
        <div style={{ marginBottom: "7em" }}></div>
        <Tabs></Tabs>
        <Gallery />
        <Article />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
