import React from "react";
import { Container, Grid, Box } from "@mui/material";
import gif from "./ezgif.com-gif-maker (4).gif";
import laptop from "./image (3).png";
import phone from "./phone 1.png";
import brochure from "./MicroREC Connect Brochure.pdf";

const Solution = () => {
  return (
    <Box marginBottom={{ xs: 6, sm: 10 }}>
      <Container
        style={{
          backgroundColor: "#202026",
          width: "100%",
          maxWidth: "100%",
          margin: "0",
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              marginTop: "2em",
              marginBottom: "3em",
            }}
          >
            <h2
              style={{ paddingBottom: "4", fontSize: "30px", color: "white" }}
            >
              Manage all your imaging data in
              <span style={{ color: "#8E4FFF" }}> one platform</span>.
            </h2>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              marginBottom={{ xs: 4, sm: 2 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                marginTop={{ sm: "2em", md: "1em" }}
              >
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  Seamless data integration{" "}
                </div>
                <div
                  style={{
                    color: "white",
                    marginTop: "1em",
                    fontSize: "20px",
                    marginBottom: "1em",
                  }}
                >
                  Embrace efficiency with our{" "}
                  <a
                    href="/microrec-app"
                    target="_blank"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontWeight: "600",
                    }}
                  >
                    dedicated app
                  </a>
                  , designed for seamless data capture. Experience the power of
                  instant integration as your data flows effortlessly into the
                  platform.
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <img
                  src={phone}
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "contain",
                  }}
                ></img>
              </Grid>
            </Grid>{" "}
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={7} order={{ xs: 2, sm: 2, md: 1 }}>
                <div class="containerr">
                  <img src={gif} alt="Your Image" class="background-image" />
                  <img src={laptop} alt="Your GIF" class="overlay-gif" />
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                order={{ xs: 1, sm: 1, md: 2 }}
                marginTop={{ sm: "2em", md: "0" }}
              >
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "25px",
                    color: "white",
                  }}
                >
                  Image Uploads Made Easy{" "}
                </div>
                <div
                  style={{ color: "white", marginTop: "1em", fontSize: "20px" }}
                >
                  Incorporate images from various devices into our platform,
                  enhancing your ability to visualize and manage critical
                  ophthalmic data.{" "}
                </div>
              </Grid>
            </Grid>{" "}
            <button className="shop" style={{ marginTop: "2em" }}>
              <a
                style={{ color: "white" }}
                href={brochure}
                target="_blank"
                download
              >
                DOWNLOAD BROCHURE{" "}
              </a>
            </button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Solution;
